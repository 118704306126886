// Nav.js
import React, { useState } from 'react';
import './nav.css';
import { CiMedicalCross } from "react-icons/ci";
import { LuDot } from "react-icons/lu";
import { FaMoon, FaSun } from "react-icons/fa";
import { RiFlashlightLine } from "react-icons/ri";
import myprof from './../../Assets/30428.0x600.jpg';

export default function Nav({ toggleDarkMode, isDarkMode }) {
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleLogout = () => {
        console.log("Logging out...");
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';
        window.location.reload(); // Refresh page after logout
    };

    return (
        <div className="NavContainer">
            <div className="NavContant">
                <div className="NavContantLeft">
                    <div className="LeftNavImg">
                        <p><CiMedicalCross /></p>
                    </div>
                    <div className="LeftNavStatus">
                        <h4>Стандартный план</h4>
                        <p><span><LuDot /></span> Активно 35 000 человек</p>
                    </div>
                </div>
                <div className="NavContantRight">
                    <div className="Upgrade">
                        <button>Улучшить план <span><RiFlashlightLine /></span></button>
                    </div>
                    <div className="Darkmode">
                        <button onClick={toggleDarkMode}>
                            {isDarkMode ? <FaSun /> : <FaMoon />}
                        </button>
                    </div>
                    <div className="Profile">
                        <button onClick={toggleDropdown}>
                            <div className="Profile-img">
                                <img src={myprof} alt="Profile" />
                            </div>
                            <div className="Profile-text">
                                <p>Yunusov MukhammadAli</p>
                            </div>
                        </button>
                        {isDropdownOpen && (
                            <div className="DropdownMenu">
                                <ul> 
                                    <li className='Standart'><h4>Стандартный план</h4><p><span><LuDot /></span> Активно 35 000 человек</p></li>
                                    <li className='Ulutshit'><button>Улучшить план <span><RiFlashlightLine /></span></button></li>
                                    <li className='darkjala' onClick={toggleDarkMode}><button >{isDarkMode ? <FaSun /> : <FaMoon />} </button></li>
                                    <li onClick={handleLogout}>Выйти</li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
