import "./Dashboard.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaTelegramPlane } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";

export default function Dashboard() {
  const [inputValue, setInputValue] = useState("");
  const [chatMessages, setChatMessages] = useState(() => {
    const savedMessages = localStorage.getItem("chatMessages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("authToken") || ""
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refreshToken") || ""
  );
  const [loading, setLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authToken) {
      navigate("/login");
    }
  }, [authToken, navigate]);

  useEffect(() => {
    localStorage.setItem("chatMessages", JSON.stringify(chatMessages));
  }, [chatMessages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValue.trim() === "") return;

    setChatMessages((prevMessages) => [
      ...prevMessages,
      { sender: "user", text: inputValue },
    ]);
    setInputValue("");
    setLoading(true);
    setIsTyping(true);

    try {
      const response = await axios.post(
        "https://med.posox.uz/api/chat/to/gpt/",
        {
          message: inputValue,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );

      if (response.status === 200 || response.status === 201) {
        const botMessage = { sender: "bot", text: response.data.bot_response };
        setChatMessages((prevMessages) => [...prevMessages, botMessage]);
        setIsTyping(false);
        setLoading(false);
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching the bot response:", error);
      if (error.response && error.response.status === 401) {
        await handleTokenRefresh();
      }
      setLoading(false);
      setIsTyping(false);
    }
  };

  const handleTokenRefresh = async () => {
    if (!refreshToken) {
      handleLogout();
      return;
    }

    try {
      const response = await axios.post(
        "https://med.posox.uz/api/token/refresh/",
        {
          refresh: refreshToken,
        }
      );

      if (response.status === 200) {
        const newToken = response.data.access;
        localStorage.setItem("authToken", newToken);
        setAuthToken(newToken);
      } else {
        console.error("Failed to refresh token:", response.status);
        handleLogout();
      }
    } catch (error) {
      console.error(
        "Refresh token failed:",
        error.response ? error.response.data : error.message
      );
      handleLogout();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("chatMessages");
    navigate("/login");
  };

  const handleNewChat = () => {
    setChatMessages([]);
    setInputValue("");
    setIsTyping(false);
    localStorage.removeItem("chatMessages");
  };

  return (
    <div className="dashboard-container">
      <div className="chat-container">
        {chatMessages.map((message, index) => (
          <div key={index} className={`chat-message ${message.sender}`}>
            {message.sender === "bot" ? (
              <div className="BotOtvet">
                <div dangerouslySetInnerHTML={{ __html: message.text }} />
              </div>
            ) : (
              message.text
            )}
          </div>
        ))}
        {isTyping && <div className="typing-indicator">...</div>}
      </div>
      <form className="dashboard-form" onSubmit={handleSubmit}>
        <input
          type="text"
          className="dashboard-input"
          placeholder="Напишите Наташе"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <button type="submit" className="dashboard-button">
          <FaTelegramPlane />
        </button>
        <button
          type="button"
          className="dashboard-button"
          onClick={handleNewChat}
        >
          <FaPlus />
        </button>
      </form>
    </div>
  );
}
