import React, { useEffect, useState } from 'react';
import './Left.css';
import { HiSquare2Stack } from "react-icons/hi2";
import { FaBars } from "react-icons/fa";
import axios from 'axios';

export default function Left() {
    const [chatHistory, setChatHistory] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const fetchChatHistory = async () => {
            try {
                const response = await axios.get('https://med.posox.uz/api/chat/to/gpt/');
                setChatHistory(response.data);
            } catch (error) {
                console.error('Error fetching chat history:', error);
            }
        };

        fetchChatHistory();
    }, []);

    return (
        <>
            <div className="LeftContainer">
                {/* Existing layout */}
                <div className="LeftContant">
                    <div className="LeftTitle">
                        <p>Основная</p>
                    </div>
                    <div className="LeftHistory">
                        {chatHistory.length > 0 ? (
                            chatHistory.map((history, index) => (
                                <div className="HistoryBox" key={index}>
                                    <p>{history.title}</p>
                                </div>
                            ))
                        ) : (
                            <p>История чатов пуста</p>
                        )}
                    </div>
                    <div className="LeftDown">
                        <p><HiSquare2Stack /> History</p>
                    </div>
                </div>
            </div>

            <div className="BurgerMenu">
                <FaBars onClick={() => setIsMenuOpen(!isMenuOpen)} />
            </div>

            <div className={`BurgerMenuContent ${isMenuOpen ? 'open' : ''}`}>
                <div className="LeftTitle">
                    <p>Основная</p>
                </div>
                <div className="LeftHistory">
                    {chatHistory.length > 0 ? (
                        chatHistory.map((history, index) => (
                            <div className="HistoryBox" key={index}>
                                <p>{history.title}</p>
                            </div>
                        ))
                    ) : (
                        <p>История чатов пуста</p>
                    )}
                </div>
                <div className="LeftDown">
                    <p><HiSquare2Stack /> History</p>
                </div>
            </div>
        </>
    );
}
