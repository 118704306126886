import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Nav from "./Components/Navbar/Nav";
import Dashboard from "./Page/Dashboard/Dashboard";
import Login from "./Page/Login/Login";
import Left from "./Components/LeftMenu/Left";
import React, { useState, useEffect } from "react";

function App() {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  const [isDarkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const savedDarkMode = localStorage.getItem("darkMode") === "true";
    setDarkMode(savedDarkMode);
    document.body.classList.toggle("dark-mode", savedDarkMode);
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode);
    document.body.classList.toggle("dark-mode", !isDarkMode);
    localStorage.setItem("darkMode", !isDarkMode);
  };
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/login"
            element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
          />
          <Route
            path="/dashboard"
            element={
              isAuthenticated ? (
                <>
                  <div className="vmeste">
                    <Left />
                    <div className="Vmestedwa">
                      <Nav
                        toggleDarkMode={toggleDarkMode}
                        isDarkMode={isDarkMode}
                      />
                      <Dashboard />
                    </div>
                  </div>
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="*"
            element={
              <Navigate to={isAuthenticated ? "/dashboard" : "/login"} />
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
